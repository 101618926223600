import {auth} from "../utils/init-firebase";
import {createUserWithEmailAndPassword} from "firebase/auth";

export const setAuth = (password)=>{
    switch(password){
        case "123": 
         return {
             type: "SET_AUTH",
             authObj: {
                type: "employer",
                firstName: "Farai",
                lastName: " Mashonganyika",
                companyName: "ByteArc",
                role: "Recruiter",
                industry: "Information Technology",
                website: "https://bytearc.io",
                employees: "50-99",
                companyPic: "https://i.ibb.co/qnQk3d8/profile.png",
                profilePic: "https://firebasestorage.googleapis.com/v0/b/new-portfolio-34646.appspot.com/o/farai.jpg?alt=media&token=d2f82e71-7497-4377-aa07-8258d249aa9b",
                phoneNumber: "568-473-4027",
                address: "123 Main Street",
                city: "Fort Worth",
                state: "Texas",
                zipCode: "9000",
                email: "faraimasho@bytearc.io",
                country: "United States"
             }
         };
         case "321": 
           return {
               type: "SET_AUTH",
               authObj: {
                type: "job_seeker",
                firstName: "Douglas",
                lastName: " Mashonganyika",
                website: "https://douglasmasho.ml",
                resume: {
                    fileName: "mycv.pdf",
                    link: "https://firebasestorage.googleapis.com/v0/b/new-portfolio-34646.appspot.com/o/douglas-mashonganyika-cv_3.pdf?alt=media&token=51f33bda-50ac-4241-86ab-bd2fb34b9b4c"
                },
                expertise: "Software Engineering",
                profilePic: "https://avatars.githubusercontent.com/u/51298460?v=4?s=400",
                phoneNumber: "568-473-4027",
                address: "321 Main Street",
                city: "San Fransisco",
                state: "California",
                zipCode: "9000",
                email: "douglasmasho@bytearc.io",
                country: "United States"
               }
           }
        default: return {
            type: "DEFAULT"
        }
    }
}


export const setAuthToNull = ()=>{
    return {
        type: "SET_AUTH",
        authObj: ""
    }
}


export const setAuth2 = (user)=>{
    //use firebase to check if the user doc exists, if it does not, create one
    return {
        type: "SET_AUTHENTICATION",
        authObj: user
    }
}


export const increaseCounter = (num)=>{
    return {
        type: "INCREASE",
        count: num
    }
}

export const register=(email, password)=>{
    // createUserWithEmailAndPassword(auth, email, password);
}


export const setJobs=(jobsArr)=>{
    return {
        type: "SET_JOBS",
        jobs: jobsArr
    }
}