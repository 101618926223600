import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDB3GZkHAX37dVD6AkQjd0JoBvntjhrBpQ",
  authDomain: "lubin-talent-solutions.firebaseapp.com",
  projectId: "lubin-talent-solutions",
  storageBucket: "lubin-talent-solutions.appspot.com",
  messagingSenderId: "163296882037",
  appId: "1:163296882037:web:dbaaee6111da163aa8f73c",
  measurementId: "G-CEFWJCRGV2"
};

firebase.initializeApp(firebaseConfig);
//for analytics
firebase.analytics();

export default firebase;