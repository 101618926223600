import React from 'react'
import firebase from "firebase/app";
const firestore = firebase.firestore();


const Hit = ({hit, setSelected, throwToast, uid, setAuth, auth}) => {
    return (
        <div className='result'>
            <div className="result__img"  style={{backgroundImage: `url(${hit.companyPic})`}}></div>
            <div className="result__inner">
                <p className="bigger-text result__header">{hit.companyName}</p>
                <div>
                <button className='button' onClick={async()=>{
                    setSelected(hit);
                    try{
                        const snapshot = await firestore.collection("users").doc(uid).update({
                            cid: hit.uid
                        });
                        setAuth({
                            ...auth,
                            cid: hit.uid
                        })
                        setSelected(snapshot.data())
                      }catch(e){
                        throwToast("error", "Error Getting company");
                      }
                }}>Select</button>
                </div>
            </div>
        </div>
    )
}

export default Hit
