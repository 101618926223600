import {combineReducers} from "redux";
import {persistReducer, createTransform} from "redux-persist";
import JSOG from 'jsog';
import storage from "redux-persist/lib/storage";

export const JSOGTransform = createTransform(
    (inboundState, key) => JSOG.encode(inboundState),
    (outboundState, key) => JSOG.decode(outboundState),
)


const persistConfig  = {
    key: "root",
    storage,
    whiteList: ["authReducer", "jobsReducer"],
    transforms: [JSOGTransform]
}


const authReducer = (state=null, action)=>{
    switch(action.type){
        case "SET_AUTH": 
           return action.authObj;
        default: return state;
    }
}

const auth = (state=null, action)=>{
    switch(action.type){
        case "SET_AUTHENTICATION": 
           return action.authObj;
        default: return state;
    }
}


const count = (state=0, action)=>{
    switch(action.type){
        case "INCREASE": 
           return state + action.count;
        default: return state;
    }
}


const jobsReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_JOBS": 
           return action.jobs;
        default: return state;
    }
}




const rootReducer = combineReducers({
    authObj: authReducer,
    auth: auth,
    count: count,
    jobs: jobsReducer
})

export default persistReducer(persistConfig, rootReducer);


