import {createContext, useContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/auth";

//this is for the function that registers a user
const AuthContext = createContext({
     currentUser: null,
     register: ()=>Promise,
     login: ()=>Promise,
     signOut: ()=>Promise,
     signInWithGoogle: ()=>Promise,
     signInWithFacebook: ()=>Promise
});

//create a custom hook that will consume the useContext hook;

export const useAuth = ()=> useContext(AuthContext);

const AuthContextProvider=({children})=>{
    const [currentUser, setCurrentUser] = useState(null);

    //this function returns a function call which returns a promise that will create a user using firebase authentication
    const register = (email, password)=>{
        // return firebase.auth().createUserWithEmailAndPassword(auth, email, password);
        return firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    const login = (email, password)=>{
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    const signOut = ()=>{
        return firebase.auth().signOut();
    }

    const signInWithGoogle = ()=>{
        const  provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth().signInWithPopup(provider);
    }

    const signInWithFacebook = ()=>{
        const  provider = new firebase.auth.FacebookAuthProvider();
        return firebase.auth().signInWithPopup(provider);
    }
  
    const value = {
        currentUser,
        register,
        setCurrentUser,
        login,
        signOut,
        signInWithGoogle,
        signInWithFacebook
    }
    return  (<AuthContext.Provider value={value}>
              {children}
         </AuthContext.Provider>)
}

export default AuthContextProvider;
