import React from 'react'
import { Link } from 'react-router-dom';
import Logo from "../assets/SVG/logodarkbg.svg";
import { FaLinkedin, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
    return (
        <div style={{position: "relative",}}>
            <div className="footer">
                <div className="footer-row">
                    <div className="footer__logo-container center-vert">
                        <img src={Logo} alt="" className="footer__logo"/>
                        <div style={{display: "flex", justifyContent: "space-evenly"}} className="u-margin-top">
                           <a href="https://www.linkedin.com/company/4815132/admin" target="_blank" className="footer__social"><FaLinkedin/></a> 
                           <a href="https://www.facebook.com/LubinTS" className="footer__social" target="_blank"><FaFacebook className="footer__social"/></a>
                           {/* <a href="/" className="footer__social" target="_blank"><FaInstagram className="footer__social"/></a> */}
                           <a href="https://twitter.com/LubinTalSol" className="footer__social" target="_blank"><FaTwitter className="footer__social"/></a>
                        </div>
                    </div>
                    <div className="footer__list">
                        <h3>Company</h3>
                        <ul>
                            <li className="footer__list-item"><Link to="/about">About Us</Link></li>
                            <li className="footer__list-item"><Link to="/foremployers">For Employers</Link></li>
                            <li className="footer__list-item"><Link to="/jobs">For Job Seekers</Link></li>
                            <li className="footer__list-item"><Link to="/ourspecializations">Our Speacializations</Link></li>
                            <li className="footer__list-item"><Link to="/privacypolicy">Privacy Policy</Link></li>
                            <li className="footer__list-item"><Link to="/termsandconditions">Terms and Conditions</Link></li>
                        </ul>
                    </div>
                    <div className="footer__list">
                        <h3>Features</h3>
                        <ul>
                        <li className="footer__list-item"><Link to="/foremployers">Contract Talent</Link></li>
                        <li className="footer__list-item"><Link to="/foremployers">Permanent Placement</Link></li>
                        <li className="footer__list-item"><Link to="/foremployers">Temp-to-Hire</Link></li>
                        <li className="footer__list-item"><Link to="/jobs">Browse Jobs</Link></li>
                        </ul>
                    </div>
                    <div className="footer__list">
                        <h3>Resources</h3>
                        <ul>
                        <li className="footer__list-item"><Link to="/underconstruction">Blog</Link></li>
                        <li className="footer__list-item"><Link to="/underconstruction">Invoices</Link></li>
                        <li className="footer__list-item"><Link to="/underconstruction">Time</Link></li>
                        </ul>
                    </div>
                    <div className="footer__list">
                        <h3>Contact</h3>
                        <ul>
                        <li className="footer__list-item">Fort Worth, Texas</li>
                        <li className="footer__list-item">United States</li>
                        <li className="footer__list-item"><a href="mailto:support@Lubin Talent Solutions.com">support@lubintalentsolutions.com</a></li>
                      
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
