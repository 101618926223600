import React, {useRef, useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { HamburgerArrow } from 'react-animated-burgers';
import Logo from "../assets/SVG/logodarkbg.svg";
import { useHistory } from 'react-router';
import {connect} from "react-redux";
import * as actionCreators from "../redux/actions";
import { bindActionCreators } from 'redux';
import {useAuth} from "../context/AuthContext";
import firebase from "firebase/app";

const Navbar = (props) => {
const history = useHistory();
    const [activeState, setActiveState] = useState(false);
    const mobileNavRef = useRef();
    const {signOut} = useAuth();


    const signOutFunc = (e)=>{
        props.setAuthToNull();
        async function signoutFunc(){
            try{
                await signOut();
                props.setAuth2("");
            }catch(e){
                console.log(e)
            }
        }
        signoutFunc();
    }

    return (
        <>
          <div className="nav">
            <div className="logo-container">
               <Link to="/"><img src={Logo} alt="" className="nav__logo"/></Link>
            </div>


            <div className="link-container">
                <ul>
                <li className="nav__link"><Link to="/about" >About Us</Link></li>
                    <li className="nav__link sub-menu-link-2"><Link to="/jobsapply">Search Jobs</Link></li>
                    <li className="nav__link sub-menu-link-2"><Link to="/expertise">Expertise</Link></li>
                    <li className="nav__link"><Link to="/foremployers">For Employers</Link></li>

                     <li className="nav__link nav__link--1 sub-menu-link-3">
                           Resources<i className="fas fa-chevron-down u-margin-left-tiny"></i>
                           <div className="sub-menu-3">
                            <ul>
                            <Link to="/underconstruction"><li className="sub-link">Blog</li></Link>
                            <Link to="/underconstruction"><li className="sub-link">Invoices</li></Link>
                            <Link to="/underconstruction"><li className="sub-link">Time</li></Link>  
                            </ul>
                        </div>
                        </li>

                    <li className="nav__link sub-menu-link-2"><Link to="/contact" >Contact Us</Link></li>
                    {
                        props.auth ? (
                            <>
                                <li className=" nav__link--special button" id="button--login" onClick={()=>history.push("/dashboard/account")}>Dashboard</li> 
                                <li className=" nav__link--special button button--getstarted" id="button--login" onClick={signOutFunc}>Sign Out</li> 
                            </>
                        ) : 
                        <>
                          <li className=" nav__link--special button" id="button--login" onClick={()=>history.push("/login")}>Log In</li>
                           <li className="nav__link--special button button--getstarted" onClick={()=>history.push("/signup")}>Get Started</li>
                        </> 
                    }
                   
                </ul>
            </div>


        </div>

        <div className="nav--mobile">

            <div className="logo-container">
               <Link to="/"><img src={Logo} alt="" className="nav__logo"/></Link>
            </div>
            <div className="center-vert u-margin-right">
                    <HamburgerArrow barColor={props.barColor}  isActive={activeState} toggleButton={()=>{
                    setActiveState(!activeState);
                }}/>
            </div>
        </div>

        {
            activeState ? 
            <div>
            <div className="link-container__mobile" ref={mobileNavRef}>
                   
                <ul>
                <li className="nav__link nav__link--mobile" onClick={()=>{setActiveState(!activeState);}}><Link to="/about" >About Us</Link></li>
                <li className="nav__link nav__link--mobile" onClick={()=>{setActiveState(!activeState);}}><Link to="/jobsapply" >Search Jobs</Link></li>
                <li className="nav__link nav__link--mobile" onClick={()=>{setActiveState(!activeState);}}><Link to="/expertise">Expertise</Link></li>
                    <li className="nav__link nav__link--mobile" onClick={()=>{setActiveState(!activeState);}}><Link to="/foremployers" >For Employers</Link></li>
                    <li className="nav__link sub-menu-link-6">
                       <a className="nav__link--mobile">Resources</a>
                       <div className="sub-menu-6 sub-menu-6--center">
                            <ul>
                            <Link to="/blog" onClick={()=>{setActiveState(!activeState);}}><li className="sub-link">Blog</li></Link>
                            <Link to="/dashboard/invoices" onClick={()=>{setActiveState(!activeState);}}><li className="sub-link">Invoices</li></Link>
                            <Link to="/login" onClick={()=>{setActiveState(!activeState);}}><li className="sub-link">Time</li></Link>
                            </ul>
                        </div>
                    </li>
                    <li className="nav__link nav__link--mobile" onClick={()=>{setActiveState(!activeState);}}><Link to="/contact">Contact Us</Link></li>
                    {
                       props.auth ? 
                       <>
                        <li className=" nav__link--special" onClick={()=>{
                        setActiveState(!activeState);
                        history.push("/dashboard/account");
                        }}><Link to="/login" className="button" id="button--login">Dashboard</Link></li>
                   <li className="nav__link--special" onClick={()=>{
                       setActiveState(!activeState);
                       props.setAuthToNull();
                    ;}}> <button to="/signup" className="button button--getstarted" onClick={signOutFunc}>Sign Out</button></li>
                       </>  : 
                       <>
                        <li className=" nav__link--special" onClick={()=>{
                        setActiveState(!activeState);
                        history.push("/login");
                        }}><Link to="/login" className="button" id="button--login">Log In</Link></li>
                   <li className="nav__link--special" onClick={()=>{
                       setActiveState(!activeState);
                       history.push("/signup");
                    ;}}> <Link to="/signup" className="button button--getstarted">Get Started</Link></li>
                    {/* <li className="nav__link" onClick={()=>{setActiveState(!activeState);}}><a href="#contactus">Contact</a></li> */}
                       </>
                    }
                   
                </ul>
            </div>
            </div> : null
        }
        </>

    )
}

const mapStateToProps = state=>({ //this is the state in the store ///this will take the state from the store and put it as props in the component that is being connected
    authObj: state.authObj,
    auth: state.auth,
  });
  
  const mapDispatchToProps = dispatch=>{ //this will allow you to dispatch actions from anywhere in the compoonent
    return bindActionCreators(actionCreators, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
