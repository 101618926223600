import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import { useLocation, useParams } from 'react-router-dom';

class ScrollToTop extends Component{
    componentDidUpdate(prevProps){
        if(this.props.location !== prevProps.location && this.props.location.pathname.split("/")[1] !== "jobs"){
            this.props.scrollToTop();
        }

        if(this.props.location !== prevProps.location && this.props.location.pathname.split("/")[1] === "jobs"){
            if(this.props.location.pathname.split("/").length === 2){
          
                this.props.scrollToTop();
            }else{
                document.querySelector(".board__right").scrollTo(0,0);
                document.querySelector(".board").scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'start' });
                document.querySelector(".board__right").classList.add("fadeinClass")  
            }
        }
    }

    render(){
        return null;
    }
}

export default withRouter(ScrollToTop);