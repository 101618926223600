import React from "react";
import ReactDOM from "react-dom";
import "./sass/main.scss";
import { BrowserRouter } from "react-router-dom";

import { createStore } from "redux";
import { Provider } from "react-redux";
import {persistStore} from "redux-persist"; 
import rootReducer from "./redux/reducers";

import App from "./App";
import { ChakraProvider } from '@chakra-ui/react'

let store = createStore(rootReducer);
const persistor = persistStore(store);


ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);
